.mkg-li {
    padding: 10px;
    text-align: left;
    list-style: none; /* Remove default list styles */
    border-radius: 5px;
  }
  
  .mkg-li a {
    text-decoration: none !important;
    color: var(--theme1); /*Default text color for the link (blue in this case) */
  }
  
  .mkg-li:hover {
    background-color: var(--theme8); /* Slightly darker lavender for hover effect */
  }
  
  .mkg-li:hover a {
    cursor: pointer;
    color: var(--theme1) !important; /* White text color on link hover */
  }
  
  .mkg-active-link {
    text-decoration: none !important;
    background-color: var(--theme2); /*Slightly darker lavender for hover effect */
  }
  
  .mkg-active-link a{
    color: var(--theme4) !important; 
  }