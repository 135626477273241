:root {
  --theme1:#1C0037;
  --theme2:#4E1E6C;
  --theme3:#C77DFF;
  --theme4:#FFF5F9;
  --theme5:#FFE9A8;
  --theme6:#BBF2AD;
  --theme7:#392FC0;
  --theme8:#EAEAEA;
}

.App {
  text-align: center;
  height: max-content;
  position: relative;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.NewComponent {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2; /* Adjust z-index to control stacking order */
}

.mui-button-primary {
    background-color: #007bff;
    color: '#ffffff';
    border-radius: '115px'; 
    padding: '10px 20px';
}
.mui-button-secondary {
    background-color: #007bff;
    color: '#ffffff';
    border-radius: '5px'; 
    padding: '10px 20px';
}
.mui-button-alert {
    background-color: #007bff;
    color: '#ffffff';
    border-radius: '5px'; 
    padding: '10px 20px';
}
.mui-button-success {
    background-color: #007bff;
    color: '#ffffff';
    border-radius: '5px'; 
    padding: '10px 20px';
}

.display-1{
  font-size: 3rem;  
}
.display-2{
  font-size: 2.1rem;
}
.display-3{
  font-size: 1.3rem;
}
.display-4{
  font-size: 1rem;
}
.display-5{
  font-size: 0.7rem;
}
.display-6{
  font-size: 0.66rem;
}
.display-7{
  font-size: 0.33rem;
}

.icon-white{
  color: white !important;
  background: transparent;
}
.text-white{
  color: white;
  text-decoration: none !important;/* !important will override the bootstrap*/
}

