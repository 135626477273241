.layout-container {
    height: 100vh;
    overflow: hidden;
  }
  /*
    We set the height of the content area to calc(100vh - 60px), where 60px is the assumed height of your header. This ensures that the content      area takes up the full viewport height minus the height of the header.
    We added overflowY: 'auto' to the content area's inline styles. This property enables vertical scrolling when the content exceeds the available height. */
.content-area {
    height: calc(100vh - 10vh); /* Adjust based on your header height */
    /* overflow: none; */
    overflow-y: auto;
    scrollbar-width: none;
  }
