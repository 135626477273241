/*----------------------------------------------- success card CSS start -----------------------------------------------*/
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999; /* higher z-index to appear above other content */
}

.modal-confirm {
  color: #636363;
  width: 325px;
  font-size: 14px;
  /* z-index: 99; */
}

.modal-confirm .modal-content {
  padding: 20px;
  border-radius: 5px;
  border: none;
}

.modal-confirm .modal-header {
  border-bottom: none;
  position: relative;
}

.modal-confirm h4 {
  text-align: center;
  font-size: 26px;
  margin: 30px 0 -15px;
}

.modal-confirm .form-control,
.modal-confirm .btn {
  min-height: 40px;
  border-radius: 3px;
}

.modal-confirm .close {
  position: absolute;
  top: -5px;
  right: -5px;
}

.modal-confirm .modal-footer {
  border: none;
  text-align: center;
  border-radius: 5px;
  font-size: 13px;
}

.modal-confirm .icon-box {
  color: #fff;
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: -70px;
  width: 95px;
  height: 95px;
  border-radius: 50%;
  z-index: 9;
  background: #82ce34;
  padding: 15px;
  text-align: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.modal-confirm .icon-box i {
  font-size: 58px;
  position: relative;
  top: 3px;
}

.modal-confirm.modal-dialog {
  margin-top: 80px;
}

.modal-confirm .btn {
  color: #fff;
  border-radius: 4px;
  background: #82ce34;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  border: none;
}

.modal-confirm .btn:hover,
.modal-confirm .btn:focus {
  background: #6fb32b;
  outline: none;
}

.trigger-btn {
  display: inline-block;
  margin: 100px auto;
}
/*----------------------------------------------- success card CSS end -----------------------------------------------*/